<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :return-value="date"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formatedDate"
        :data-layout="dataLayout"
        @focus="$emit('focus')"
        :rules="rules"
        :label="label"
        :disabled="disabled"
        :readonly="readonly"
        :outlined="outlined"
        :dense="dense"
        :hide-details="hideDetails"
        :prepend-inner-icon="prependInnerIcon"
        :append="appendIcon"
        :clearable="clearable"
        @click:clear="$emit('input', '');$emit('click:clear')"
        :class="customClass"
        v-bind="attrs"
        v-on="on"
      />
    </template>

    <v-date-picker
      v-if="menu"
      v-model="date"
      :max="max"
      :min="min"
      :show-current="showCurrent"
      :no-title="noTitle"
      :scrollable="scrollable"
      :type="type"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        {{ $t("generic.lang_cancel") }}
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">
        {{ $t("generic.lang_apply") }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    dataLayout: {
      type: String,
      default: "normal",
    },
    rules: Array,
    customClass: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    dateFormat: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "date",
    },
    showCurrent: {
      type: Boolean,
      default: true,
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "2030-01-01",
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    menu: false,
  }),
  computed: {
    formatedDate(){
      if(!this.dateFormat)
        return this.value
      
      if(this.value){
        let m = this.$moment(this.value);
        if(m.isValid())
          return m.format(this.dateFormat);
      }

      return this.value
    },
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
